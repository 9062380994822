<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
                <FilterProvince
                    class="mr-2"
                    v-model:value="state.params.provinsi"/>
                <FilterDistributor
                    class="mr-2"
                    v-model:value="state.params.distributor"
                    v-model:propinsi="state.params.provinsi"/>
                <FilterGudang
                    class="mr-2"
                    v-model:value="state.params.gudang"
                    v-model:distributor="state.params.distributor"
                    v-model:propinsi="state.params.provinsi"/>
                <ADatePicker
                    class="mr-2"
                    placeholder="Pilih Tanggal"
                    style="width:300px;"
                    v-model:value="state.params.date"
                    format="DD MMMM YYYY"/>
                <AButton
                    class="mr-2"
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </div>
        </div>

        <!-- button action -->
        <div class="row justify-content-end">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <AButton
                    class="mr-3"
                    title="Download Excel"
                    type="primary"
                    @click="btnDownloadExcel"
                    :loading="state.download.loading">
                    <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!state.download.loading"></i>
                    <span v-else>Downloading ...</span>
                </AButton>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                row-key="id"
                :columns="state.columns"
                :data-source="state.data"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">
                <template #no="{ index }">
                    <span>
                    {{ startRow + index }}
                    </span>
                </template>
            </MdTable>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, onMounted, computed, ref } from 'vue'
import apiClient from '@/services/axios'
import { message } from 'ant-design-vue'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterGudang from '@/components/filter/FilterGudang'
import { pickBy } from 'lodash'
import moment from 'moment'
import Form from 'vform'
import {
    FormatCurrency,
} from '@/helpers/utils'

export default defineComponent({
    components: {
        FilterProvince,
        FilterDistributor,
        FilterGudang,
    },
    setup() {
        const errorMessage = ref(null)

        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Tanggal',
                    dataIndex: 'date',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'distributor_code',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'distributor_name',
                },
                {
                    title: 'Kode Gudang',
                    dataIndex: 'warehouse_code',
                },
                {
                    title: 'Nama Gudang',
                    dataIndex: 'warehouse_name',
                },
                {
                    title: 'Kapasitas Gudang',
                    dataIndex: 'warehouse_kapasitas',
                },
                {
                    title: 'Level Stok',
                    children: [
                        {
                            title: 'Sell In',
                            dataIndex: 'sellin',
                            width: 70,
                        },
                        {
                            title: 'Sell Out',
                            dataIndex: 'sellout',
                            width: 70,
                        },
                        {
                            title: 'Stok Akhir',
                            dataIndex: 'stock_akhir',
                            width: 90,
                        },
                        {
                            title: 'Level Stok',
                            dataIndex: 'level_stock',
                            width: 90,
                            customRender: ({ text }) => FormatCurrency(text)+'%',
                        },
                    ],
                },
                {
                    title: 'Simulasi Level Stok',
                    children: [
                        {
                            title: 'Stok Awal',
                            dataIndex: 'simulasi_stock_awal',
                            width: 90,
                        },
                        {
                            title: 'Auto Sell In',
                            dataIndex: 'simulasi_sellin',
                            width: 100,
                        },
                        {
                            title: 'Sell Out',
                            dataIndex: 'simulasi_sellout',
                            width: 90,
                        },
                        {
                            title: 'Stok Akhir',
                            dataIndex: 'simulasi_stock_akhir',
                            width: 90,
                        },
                        {
                            title: 'Level Stok',
                            dataIndex: 'simulasi_level_stock',
                            width: 90,
                            customRender: ({ text }) => FormatCurrency(text)+'%',
                        },
                    ],
                },
            ],
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: {
                search: '',
                distributor: [],
                gudang: [],
                provinsi: [],
                kabupaten: [],
                area: [],
                region: [],
                date: moment().subtract(1, 'days'),
                page: 1,
                "per-page": 10,
            },
            download: {
                loading: false,
            },
        })

        const startRow = computed(() => (state.params.page - 1) * state.params["per-page"] + 1)

        const queryParams = () => {
            let params = state.params

            if (state.params.date) {
                state.params.date = moment(state.params.date).format('YYYY-MM-DD')
            }

            return pickBy(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get('/api/report/simulasi-stok', {
                    params: pickBy(queryParams()),
                })
                .then(({ data }) => {
                    const { items, _meta } = data
                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const btnDownloadExcel = () => {
            state.download.loading = true

            apiClient
                .get('/api/report/simulasi-stok', {
                    params: {
                        ...pickBy(queryParams()),
                        _export: 'xls',
                    },
                    responseType: 'blob',
                    headers: {},
                })
                .then(response => {
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }),
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute(
                        'download',
                        `Simulasi-Stok_${moment().format('DDMMYY_HHmm')}.xlsx`,
                    )
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.download.loading = false
                })
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize
            
            startRow.value = (page.current - 1) * page.pageSize + 1

            fetchDataList()
        }

        // handle vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            errorMessage,
            btnDownloadExcel,
            handleTableChange,
            fetchDataList,
            state,
            startRow,
        }
    },
})
</script>
<style scoped>
.card-body {
    margin-left: 20px;
}

.button {
    margin: 0 3px;
}
</style>
